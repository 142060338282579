
import Vue from 'vue'
import { mapGetters } from 'vuex'

interface TabsInterface {
  name: string,
  to: {
    name: string
  }
}

export default Vue.extend({
  name: 'MainView',
  data: () => ({
    fromOldLK: false,
    tabs: [
      {
        name: 'main.tabs.signIn',
        to: {
          name: 'SignIn'
        }
      },
      {
        name: 'main.tabs.signUp',
        to: {
          name: 'SignUp'
        }
      }
    ] as Array<TabsInterface>,
    cookiesBannerHeight: 0,
    cookiesBannerEL: null as HTMLElement | null,
    resizeObserver: null as ResizeObserver | null
  }),
  computed: {
    ...mapGetters('user', ['getIsAuthenticated']),
    heightSubtracted () {
      const subtracted = this.cookiesBannerHeight ? `${this.cookiesBannerHeight}px - 41px` : '125px'
      if (this.maw(959)) {
        return `calc(100 * var(--tVH) - ${subtracted} - 10vh)`
      }
      if (this.maw(767)) {
        return `calc(100 * var(--tVH) - ${subtracted} - 7vh)`
      }
      return 'auto'
    }
  },
  watch: {
    getIsAuthenticated (isAuthenticated): void {
      const vm = this as any
      if (isAuthenticated) {
        vm.$router.replace({ name: 'whitelistID' })
      }
    }
  },
  methods: {
    handleTabClick (to: TabsInterface) {
      const vm = this as any
      if (vm.$route.name !== to.name) {
        vm.$router.replace(to)
      }
    },
    calculateCookiesBannerHeight (): void {
      if (this.cookiesBannerEL) {
        this.cookiesBannerHeight = this.cookiesBannerEL.clientHeight
      }
    }
  },
  created (): void {
    const vm = this as any
    this.fromOldLK = Boolean(this.$route.query.from_oldlk !== undefined)
    this.fromOldLK = Boolean(this.$route.query.from_oldlk !== undefined)
    if (vm.getIsAuthenticated) {
      vm.$router.replace({ name: 'whitelistID' })
    }
    this.cookiesBannerEL = document.querySelector('.mainpage-cookies-banner')
    if (this.cookiesBannerEL) {
      this.resizeObserver = new ResizeObserver(this.calculateCookiesBannerHeight)
      this.resizeObserver.observe(this.cookiesBannerEL)
    }
  },
  beforeDestroy () {
    if (this.cookiesBannerEL) {
      this.resizeObserver.unobserve(this.cookiesBannerEL)
    }
  }
})
